//important code
import React from 'react';
import { useMediaQuery } from 'react-responsive';

const featureData = [
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/b6a975fb1b9c6617a44d0489fbcf0220ad59ff4f4ae0c5aff77e51821fab13e0?apiKey=0be449f7ecab4405b7d056c64fb07853&",
    title: "Zero Inventory model",
    byline: "No need to store extra inventory at multiple warehouses for shorter delivery time.",
    altText: "Feature image for Zero Inventory model",
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/4be2f6a08fb325a57a607da52c0ea52060c1433bb07614ab63b2a04f974bb7ca?apiKey=0be449f7ecab4405b7d056c64fb07853&",
    title: "Customer Support",
    byline: "Dedicated Customer Support executives for end-to-end courier delivery support.",
    altText: "Feature image for Customer Support",
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/665b4c211b028286403bb3a6775d154e6b3da6a058dc3515536294a76d094253?apiKey=0be449f7ecab4405b7d056c64fb07853&",
    title: "Small B2B Couriers",
    byline: "Fulfill small order requirements  of your customers in a much simpler way.",
    altText: "Feature image for Small B2B Couriers",
  },
  {
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/b479af959c12878aa7e3ec4a7bad96fbcc9e4338b61adee4df1f975afe874c9d?apiKey=0be449f7ecab4405b7d056c64fb07853&",
    title: "Real-Time Tracking",
    byline: "Be assured of your parcel security with live GPS tracking of the bus.",
    altText: "Feature image for Real-Time Tracking",
  },
];

const FeatureItem = ({ title, byline, imageUrl, altText }) => {
  return (
    <div className="w-full p-4 bg-violet-950 rounded-3xl mt-5">
      <div className="flex flex-row items-center mb-4">
        <div className="w-[32%] max-md:w-full mr-4">
          <img src={imageUrl} alt={altText} className="max-w-full aspect-square w-[100px] max-md:mt-10" />
        </div>
        <div className="flex flex-col justify-center items-start">
          <h3 className="text-3xl text-orange-500" style={{ fontFamily: 'Raleway, sans-serif' }}>{title}</h3>
          <p className="text-white text-lg mt-2" style={{ fontFamily: 'Raleway, sans-serif' }}>{byline}</p>
        </div>
      </div>
    </div>
  );
};

const FeatureItemMobile = ({ title, byline, imageUrl, altText }) => {
  return (
    // <div className="w-full p-4 bg-violet-950 rounded-3xl mt-5">
    //   <div className="flex flex-row mb-4">
    //     <div className="w-[32%] max-md:w-full mr-4">
    //       <img src={imageUrl} alt={altText} className="max-w-full aspect-square w-[100px] max-md:mt-10" />
    //     </div>
    //     <div className="flex flex-col justify-center items-start">
    //       <h3 className="text-3xl text-orange-500" style={{ fontFamily: 'Raleway, sans-serif' }}>{title}</h3>
    //       <p className="text-white text-sm mt-2" style={{ fontFamily: 'Raleway, sans-serif' }}>{byline}</p>
    //     </div>
    //   </div>
    // </div>
    <div className="w-full p-4 bg-violet-950 rounded-3xl mt-5">
      <div className='flex flex-row mb-4'>
        <div className='flex mr-4'>
          <img src={imageUrl} alt={altText} className="max-w-full aspect-square w-[100px]" />
        </div>
        <div className=''>
          <h3 className="text-3xl text-orange-500" style={{ fontFamily: 'Raleway, sans-serif' }}>{title}</h3>
        </div>
      </div>
      <div className='flex flex-row w-fit'>
          <p className="text-white text-lg mt-2" style={{ fontFamily: 'Raleway, sans-serif' }}>{byline}</p>
        </div>
    </div>
  );
};

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const FeaturesSection = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' });
  const isTabletDevice = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <section className="text-white px-5 md:px-10 lg:px-20 pt-10">
      <header className={`text-4xl font-bold text-white whitespace-nowrap max-md:ml-2.5 ${isMobileDevice ? 'text-2xl' : ''}`} style={{ fontFamily: 'Raleway, sans-serif' }}>
        Why Choose Us?
      </header>
      <div className="bottom-0 ml-2 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
      <div className="max-w-screen-xl mx-auto flex justify-center mt-5">
        <div className={`grid ${isMobileDevice ? 'grid-cols-2' : isTabletDevice ? 'grid-cols-2' : 'grid-cols-3'} md:grid-cols-2 gap-x-12 gap-y-5`}>
          {featureData.map((feature) => (
            <FeatureItem
              key={feature.id}
              title={feature.title}
              byline={feature.byline}
              imageUrl={feature.imageUrl}
              altText={feature.altText}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const FeaturesSectionTab = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' });
  const isTabletDevice = useMediaQuery({ query: '(max-width: 768px)' });


  return (
    <section className="text-white px-5 md:px-16 lg:px-20 pt-10">
      <header className={`ml-2 text-4xl font-bold text-white whitespace-nowrap ${isMobileDevice ? 'text-2xl' : ''}`} style={{ fontFamily: 'Raleway, sans-serif' }}>
        Why Choose Us?
      </header>
      <div className="bottom-0 ml-3 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
      <div className="max-w-screen-xl mx-auto flex justify-center mt-5">
        <div className={`grid ${isMobileDevice ? 'grid-cols-2' : isTabletDevice ? 'grid-cols-2' : 'grid-cols-3'} md:grid-cols-2 gap-x-12 gap-y-5`}>
          {featureData.map((feature) => (
            <FeatureItem
              key={feature.id}
              title={feature.title}
              byline={feature.byline}
              imageUrl={feature.imageUrl}
              altText={feature.altText}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const FeaturesSectionMobile = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 480px)' });
  const isTabletDevice = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <section className="text-white px-5 pt-10 mt-10">
      <header className={`ml-1 text-2xl font-bold text-white whitespace-nowrap ${isMobileDevice ? 'text-2xl' : ''}`} style={{ fontFamily: 'Raleway, sans-serif' }}>
        Why Choose Us?
      </header>
      <div className="bottom-0 ml-2 mt-1.5 w-12 h-1.5 bg-orange-500"></div>
      <div className="max-w-screen-xl mx-auto mt-5 flex justify-center">
        <div className={`grid ${isMobileDevice ? 'grid-cols-1' : isTabletDevice ? 'grid-cols-1' : 'grid-cols-3'} md:grid-cols-2 gap-4`}>
          {featureData.map((feature) => (
            <FeatureItemMobile
              key={feature.id}
              title={feature.title}
              byline={feature.byline}
              imageUrl={feature.imageUrl}
              altText={feature.altText}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col pt-2.5">
        <FeaturesSection />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col pt-2.5 ">
        <FeaturesSectionTab />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col pt-2.5">
        <FeaturesSectionMobile />
      </div>
    </Mobile>
  </>
);

export default App;

