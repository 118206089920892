import React from 'react'
import Marquee from 'react-fast-marquee';
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
};

const AboutBfastTeam = () => {
    return (
        <div className="w-full min-h-full mt-20 flex flex-col justify-center items-center text-neutral-200">
            <div className='w-[100%] flex flex-col'>
                <div className='flex space-y-2 flex-col text-center mb-14'>
                    <span className='text-4xl font-bold' style={{ fontFamily: 'Raleway, sans-serif' }}>
                        OUR TEAM
                    </span>
                    <div className="flex justify-center items-center">
                        <div className="bottom-0 w-20 h-1.5 bg-orange-500"></div>
                    </div>
                </div>

                <Marquee autoFill pauseOnHover>
                    

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-2.cdn.whatsapp.net/v/t61.24694-24/357995815_1068241870976272_8926936959137120449_n.jpg?ccb=11-4&oh=01_ASCX2zBfJDctyWmcu6cYVA-2cb6SiVOon8xWC04YWbhh3w&oe=661699AE&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Narendra Kumar</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>CEO & Co-Founder</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/D4D03AQG7gvDinGQcqw/profile-displayphoto-shrink_800_800/0/1697512113621?e=1717632000&v=beta&t=HkX1AR5mghQikKNHSCVxr-jKTmdAosVwoj8cBgXLAaU" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Aniket Bhandare</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>CTO & Co-Founder</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/C5103AQEJ-tEqWeg9zg/profile-displayphoto-shrink_800_800/0/1575655234221?e=1717632000&v=beta&t=6YAJFk7vQAWDpOfXzHZsqKBpqdx0zHg7ZgyRlM51o9U" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Ekagra Bhargava</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Co-Founder</p>
                        </div>
                    </div>
    

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/D5603AQF8p-GOhMpUCQ/profile-displayphoto-shrink_800_800/0/1705760940241?e=1717632000&v=beta&t=ltjWxrMlYmypFzX2UboNAc7c4ysIdCBHyWbw_C167hw" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Shankar Kumar Nanda</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Full Stack Developer</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Hardik Kathuria</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Marketing Head</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Jatin</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Sales Head</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Rahul</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Operation Head</p>
                        </div>
                    </div>

                
                </Marquee>
            </div>
        </div>
    )
}

const AboutBfastTeamTab = () => {
    return (
        <div className="w-full min-h-full mt-20 flex flex-col justify-center items-center text-neutral-200">
            <div className='w-[100%] flex flex-col'>
                <div className='flex space-y-2 flex-col text-center mb-14'>
                    <span className='text-4xl font-bold' style={{ fontFamily: 'Raleway, sans-serif' }}>
                        OUR TEAM
                    </span>
                </div>
                <Marquee autoFill pauseOnHover>

                <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-2.cdn.whatsapp.net/v/t61.24694-24/357995815_1068241870976272_8926936959137120449_n.jpg?ccb=11-4&oh=01_ASCX2zBfJDctyWmcu6cYVA-2cb6SiVOon8xWC04YWbhh3w&oe=661699AE&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Narendra Kumar</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>CEO & Co-Founder</p>
                        </div>
                    </div>


                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/D4D03AQG7gvDinGQcqw/profile-displayphoto-shrink_800_800/0/1697512113621?e=1717632000&v=beta&t=HkX1AR5mghQikKNHSCVxr-jKTmdAosVwoj8cBgXLAaU" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Aniket Bhandare</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>CTO & Co-Founder</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/C5103AQEJ-tEqWeg9zg/profile-displayphoto-shrink_800_800/0/1575655234221?e=1717632000&v=beta&t=6YAJFk7vQAWDpOfXzHZsqKBpqdx0zHg7ZgyRlM51o9U" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Ekagra Bhargava</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Co-Founder</p>
                        </div>
                    </div>

   

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/D5603AQF8p-GOhMpUCQ/profile-displayphoto-shrink_800_800/0/1705760940241?e=1717632000&v=beta&t=ltjWxrMlYmypFzX2UboNAc7c4ysIdCBHyWbw_C167hw" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Shankar Kumar Nanda</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Full Stack Developer</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Hardik Kathuria</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Marketing Head</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Jatin</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Sales Head</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Rahul</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Operations Head</p>
                        </div>
                    </div>

               
                </Marquee>
            </div>
        </div>
    )
}

const AboutBfastTeammobile = () => {
    return (
        <div className="w-full min-h-full mt-20 flex flex-col justify-center items-center text-neutral-200">
            <div className='w-[100%] flex flex-col'>
                <div className='flex space-y-2 flex-col text-center mb-14'>
                    <span className='text-2xl font-bold' style={{ fontFamily: 'Raleway, sans-serif' }}>
                        OUR TEAM
                    </span>
                </div>
                <Marquee autoFill pauseOnHover>


                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-2.cdn.whatsapp.net/v/t61.24694-24/357995815_1068241870976272_8926936959137120449_n.jpg?ccb=11-4&oh=01_ASCX2zBfJDctyWmcu6cYVA-2cb6SiVOon8xWC04YWbhh3w&oe=661699AE&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Narendra Kumar</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>CEO & Co-Founder</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/D4D03AQG7gvDinGQcqw/profile-displayphoto-shrink_800_800/0/1697512113621?e=1717632000&v=beta&t=HkX1AR5mghQikKNHSCVxr-jKTmdAosVwoj8cBgXLAaU" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Aniket Bhandare</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>CTO & Co-Founder</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/C5103AQEJ-tEqWeg9zg/profile-displayphoto-shrink_800_800/0/1575655234221?e=1717632000&v=beta&t=6YAJFk7vQAWDpOfXzHZsqKBpqdx0zHg7ZgyRlM51o9U" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Ekagra Bhargava</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Co-Founder</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media.licdn.com/dms/image/D5603AQF8p-GOhMpUCQ/profile-displayphoto-shrink_800_800/0/1705760940241?e=1717632000&v=beta&t=ltjWxrMlYmypFzX2UboNAc7c4ysIdCBHyWbw_C167hw" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Shankar Kumar Nanda</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Full Stack Developer</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Hardik Kathuria</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Marketing Head</p>
                        </div>
                    </div>


                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Jatin</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Sales Head</p>
                        </div>
                    </div>

                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-6">
                        <img class="rounded-t-lg" src="https://media-ccu1-1.cdn.whatsapp.net/v/t61.24694-24/430609823_1110580796926732_6609379084947444131_n.jpg?ccb=11-4&oh=01_ASD26ZS0y21POXy25UnzFKQ8uXw-zkJF4AYGDdtPsZ7-sw&oe=661D5E1A&_nc_sid=e6ed6c&_nc_cat=109" alt="" />
                        <div class="p-5  text-center">
                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Rahul</h5>
                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400" style={{ fontFamily: 'Raleway, sans-serif' }}>Operations Head</p>
                        </div>
                    </div>

           
                </Marquee>
            </div>
        </div>
    )
}

const App = () => (
    <>
        <Desktop>
            <div className="flex flex-col pt-2.5">
                <AboutBfastTeam />
            </div>
        </Desktop>
        <Tablet>
            <div className="flex flex-col pt-2.5">
                <AboutBfastTeamTab />
            </div>
        </Tablet>
        <Mobile>
            <div className="flex flex-col pt-2.5">
                <AboutBfastTeammobile />
            </div>
        </Mobile>
    </>
);

export default App;