import * as React from "react";
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
};

// const ServiceCard = ({ image, title }) => (
//   <div className="flex flex-col grow px-2.5 py-5 w-full text-2xl font-medium text-black capitalize whitespace-nowrap rounded-3xl bg-zinc-300 max-md:mt-10">
//     <img loading="lazy" src={image} alt={`${title} service icon`} className="self-center aspect-[1.25] w-[188px]" />
//     <div className="mt-2.5 self-center" style={{ fontFamily: 'Raleway, sans-serif' }}>{title}</div>
//   </div>
// );

const ServicesSection = () => {
  // const services = [
  //   { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/35eaee83be5c99fab47c4f03efa390c7492740d3d7634d558a434642459c0e63?apiKey=0be449f7ecab4405b7d056c64fb07853&", title: "Intercity Courier" },
  //   { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/95fc49bc949d00e834f012570e3768dea6543850c509f09fe866ca3656562a6f?apiKey=0be449f7ecab4405b7d056c64fb07853&", title: "B2B Partnership" },
  //   { image: "https://cdn.builder.io/api/v1/image/assets/TEMP/1ffb4b2328a5b09e321d982d9307c6dfcb7994526613c7259f66d06ca7ea6d9c?apiKey=0be449f7ecab4405b7d056c64fb07853&", title: "Last Mile Delivery" },
  // ];

  return (
    <section className="flex flex-col mt-20 self-stretch pt-10 pb-5 bg-sky-900">
      <h2 className="ml-20 text-4xl font-bold text-white capitalize whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>
        Our Services
      </h2>
      <div className="bottom-0 ml-20 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
      {/* <div className="flex justify-center items-center px-16 mt-8 rounded-3xl max-md:px-5 max-md:max-w-full">
        <div className="w-full max-w-[1040px] max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            {services.map((service, index) => (
              <div key={index} className="flex flex-col w-[38%] max-md:ml-0 max-md:w-full">
                <ServiceCard image={service.image} title={service.title} />
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="flex gap-16 mt-10 m-20">
        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/35eaee83be5c99fab47c4f03efa390c7492740d3d7634d558a434642459c0e63?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-2xl font-semibold text-center tracking-tight text-gray-900 dark:text-gray-700" style={{ fontFamily: 'Raleway, sans-serif' }}>Intercity Courier</h5>
            </a>
          </div>
        </div>

        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/95fc49bc949d00e834f012570e3768dea6543850c509f09fe866ca3656562a6f?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-2xl font-semibold text-center tracking-tight text-gray-900 dark:text-gray-700" style={{ fontFamily: 'Raleway, sans-serif' }}>B2B Partnership</h5>
            </a>
          </div>
        </div>

        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ffb4b2328a5b09e321d982d9307c6dfcb7994526613c7259f66d06ca7ea6d9c?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-2xl font-semibold text-center tracking-tight text-gray-900 dark:text-gray-700" style={{ fontFamily: 'Raleway, sans-serif' }}>Last Mile Delivery</h5>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ServicesSectionTab = () => {
  return (
    <section className="flex flex-col mt-20 self-stretch pt-10 pb-5 bg-sky-900">
      <h2 className="ml-20 text-4xl font-bold text-white capitalize whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>
        Our Services
      </h2>
      <div className="bottom-0 ml-20 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
      <div className="flex gap-8 mt-10 m-20">
        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/35eaee83be5c99fab47c4f03efa390c7492740d3d7634d558a434642459c0e63?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-md font-bold text-center tracking-tight text-gray-900 dark:text-gray-900" style={{ fontFamily: 'Raleway, sans-serif' }}>Intercity Courier</h5>
            </a>
          </div>
        </div>

        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/95fc49bc949d00e834f012570e3768dea6543850c509f09fe866ca3656562a6f?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-md font-bold text-center tracking-tight text-gray-900 dark:text-gray-900" style={{ fontFamily: 'Raleway, sans-serif' }}>B2B Partnership</h5>
            </a>
          </div>
        </div>

        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ffb4b2328a5b09e321d982d9307c6dfcb7994526613c7259f66d06ca7ea6d9c?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-md font-bold text-center tracking-tight text-gray-900 dark:text-gray-900" style={{ fontFamily: 'Raleway, sans-serif' }}>Last Mile Delivery</h5>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ServicesSectionMobile = () => {
  return (
    <section className="flex flex-col mt-20 self-stretch pt-10 pb-5 bg-sky-900">
      <h2 className="ml-5 text-3xl font-bold text-white capitalize whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>
        Our Services
      </h2>
      <div className="bottom-0 ml-5 mt-1.5 w-12 h-1.5 bg-orange-500"></div>
      <div className="flex flex-col gap-8 mt-10 m-5">
        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/35eaee83be5c99fab47c4f03efa390c7492740d3d7634d558a434642459c0e63?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-lg font-bold text-center tracking-tight text-gray-900 dark:text-gray-900" style={{ fontFamily: 'Raleway, sans-serif' }}>Intercity Courier</h5>
            </a>
          </div>
        </div>

        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/95fc49bc949d00e834f012570e3768dea6543850c509f09fe866ca3656562a6f?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-lg font-bold text-center tracking-tight text-gray-900 dark:text-gray-900" style={{ fontFamily: 'Raleway, sans-serif' }}>B2B Partnership</h5>
            </a>
          </div>
        </div>

        <div class="max-w-sm bg-neutral-200 border border-gray-200 rounded-lg shadow dark:bg-neutral-200 dark:border-gray-700">
          <a href="#">
            <img class="rounded-xl" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1ffb4b2328a5b09e321d982d9307c6dfcb7994526613c7259f66d06ca7ea6d9c?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
          </a>
          <div class="p-5">
            <a href="#">
              <h5 class="mb-2 text-lg font-bold text-center tracking-tight text-gray-900 dark:text-gray-900" style={{ fontFamily: 'Raleway, sans-serif' }}>Last Mile Delivery</h5>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const App = () => (
  <>
      <Desktop>
          <div className="flex flex-col pt-2.5">
              <ServicesSection />
          </div>
      </Desktop>
      <Tablet>
          <div className="flex flex-col pt-2.5">
              <ServicesSectionTab />
          </div>
      </Tablet>
      <Mobile>
          <div className="flex flex-col pt-2.5">
              <ServicesSectionMobile />
          </div>
      </Mobile>
  </>
);

export default App;