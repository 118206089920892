import React from 'react';
import { useMediaQuery } from 'react-responsive';

const BannerButton = () => (

  <button
    type="button"
    className="justify-center self-start px-3 py-4 mt-16 text-xl leading-5 text-center text-white whitespace-nowrap rounded-md border border-sky-800 border-solid bg-violet-950 max-md:mt-10 transition duration-300 ease-in-out hover:bg-purple-800 hover:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}
  >
    Get in Touch
  </button>
);

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Banner = ({ imageSrc, altText }) => (
  <>
    <div className="relative mt-10 mx-8 rounded-lg bg-cover bg-center bg-no-repeat" style={{ backgroundImage: "url('https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1200')", height: '500px' }}>
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 60%)' }}>
        <div className="flex h-full items-center ml-10">
          <div>
            <div className="text-white text-4xl font-bold w-4/5" style={{ fontFamily: 'Raleway, sans-serif' }}>We are building the <span className="text-orange-500">Operating System</span> for Commerce</div>
            <div className="text-white text-2xl mt-7 font-medium" style={{ fontFamily: 'Raleway, sans-serif' }}>
              Trusted partner for small businesses, large marketplaces and <br /> every type of business </div>
          </div>
        </div>
      </div>
    </div>
    <div className="relative bottom-10 left-20 right-0 h-20 bg-orange-500 w-20"></div>
  </>
);

const BannerTab = ({ imageSrc, altText }) => (
  <>
    <div className="relative mt-10 mx-8 rounded-lg bg-cover bg-center bg-no-repeat" style={{ backgroundImage: "url('https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1200')", height: '500px' }}>
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 60%)' }}>
        <div className="flex h-full items-center ml-10">
          <div>
            <div className="text-white text-3xl font-bold w-11/12" style={{ fontFamily: 'Raleway, sans-serif' }}>We are building the <span className="text-orange-500">Operating System</span> for Commerce</div>
            <div className="text-white text-xl mt-7 font-medium" style={{ fontFamily: 'Raleway, sans-serif' }}>
              Trusted partner for small businesses, large marketplaces and <br /> every type of business </div>
          </div>
        </div>
      </div>
    </div>
    <div className="relative bottom-10 left-20 right-0 h-20 bg-orange-500 w-20"></div>
  </>
);

const BannerMobile = ({ imageSrc, altText }) => (
  <>
    <div className="relative w-full mt-2 rounded-lg bg-cover bg-center bg-no-repeat" style={{
      backgroundImage: "url('https://images.pexels.com/photos/6169668/pexels-photo-6169668.jpeg?auto=compress&cs=tinysrgb&w=1200')", height: '300px', /* Reduced height for mobile */
      maxHeight: '500px', /* Max height for larger screens */
      minHeight: '200px',
    }}>
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0) 60%)' }}>
        <div className="flex h-full items-center ml-10 mt-5">
          <div>
            <div className="text-white text-2xl font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>We are building the <span className="text-orange-500">Operating System</span> for Commerce</div>
            <div className="text-white text-md mt-7 font-medium" style={{ fontFamily: 'Raleway, sans-serif' }}>
              Trusted partner for small businesses, large marketplaces and <br /> every type of business </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const BannerHeader = () => {
  return (
    <>
      <div>
        <Banner imageSrc="https://img.freepik.com/free-photo/supply-chain-representation-with-van-top-view_23-2149853168.jpg?size=626&ext=jpg&ga=GA1.1.265702685.1705760080&semt=ais" altText="Dynamic and comprehensive view of bus courier services." />
      </div>
    </>
  );
};

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col pt-2.5">
        <BannerHeader />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col pt-2.5">
        <BannerTab />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col pt-2.5">
        <BannerMobile />
      </div>
    </Mobile>
  </>
);

export default App;