import React, { useState } from "react";

// const NavLink = ({ children, href, onClick }) => (
//   <a
//     href={href}
//     onClick={onClick}
//     className="block py-2 text-lg font-semibold text-white transition duration-300 ease-in-out hover:text-orange-500 hover:underline"
//   >
//     {children}
//   </a>
// );

const NavLink = ({ children, href, onClick }) => (
  <a
    href={href}
    onClick={onClick}
    className="block text-lg font-semibold text-white capitalize relative cursor-pointer transition-all duration-500 before:content-[''] before:absolute before:-bottom-1 before:left-0 before:w-0 before:h-1 before:opacity-0 before:transition-all before:duration-500 before:bg-gradient-to-r before:from-orange-600 before:via-orange-400 before:to-orange-500 hover:before:w-full hover:before:opacity-100 before:rounded-full"
  >
    {children}
  </a>
);

const HeaderBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const navItems = [
    // { name: "Home", href: "/" },
    // { name: "Services", href: "/services" },
    { name: "About Us", href: "/about" },
    // { name: "Contact", href: "/contact" },
    { name: "Blog", href: "/blog" },
  ];
  return (
    <header className="px-8 max-lg:px-4 py-3.5 text-white bg-sky-800 shadow-sm fixed top-0 left-0 w-full z-10">
      <nav className="flex justify-between items-center mx-auto">
        <a href="/" className="flex items-center" style={{ fontFamily: 'Raleway, sans-serif' }}>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f49633f1cd95c66168d92b5ed5c3db4c171dfdda96fa034909b7fe4c1984927a?apiKey=0be449f7ecab4405b7d056c64fb07853&"
            className="h-11 mr-2"
            alt="BFAST SERVICES Logo"
          />
          <div className="text-xl sm:text-3xl  font-semibold">BFAST SERVICES</div>
        </a>
        <div className="md:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={toggleMobileMenu}
          >
            {isMobileMenuOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
        <div className="hidden md:flex gap-16" style={{ fontFamily: 'Raleway, sans-serif' }}>
          {navItems.map((item) => (
            <NavLink href={item.href} key={item.name}>
              {item.name}
            </NavLink>
          ))}
        </div>
        {/* <button className="hidden md:block py-2.5 px-5 text-lg font-semibold text-white bg-violet-900 rounded-xl hover:bg-orange-500 transition-colors duration-300">
          Track Courier
        </button> */}
      </nav>
      {isMobileMenuOpen && (
        <div className="md:hidden mt-2">
          <div className="flex flex-col items-center gap-2" style={{ fontFamily: 'Raleway, sans-serif' }}>
            {navItems.map((item) => (
              <NavLink
                href={item.href}
                key={item.name}
                onClick={toggleMobileMenu}
              >
                {item.name}
              </NavLink>
            ))}
            {/* <button className=" md:block py-2.5 px-5 text-lg font-semibold text-white bg-violet-900 rounded-xl">
              Track Courier
            </button> */}
          </div>
        </div>
      )}
    </header>
  );
};

export default HeaderBar;
