import React from 'react'
import AboutHeader from '../components/AboutHeader'
import AboutBfast from '../components/AboutBfast'
import AboutMission from '../components/AboutMission'
import AboutCareer from '../components/AboutCareer'
import AboutBfastTeam from '../components/AboutBfastTeam'

const About = () => {
  return <div>
      <AboutHeader />
      <AboutBfast />
      <AboutMission />
      <AboutBfastTeam />
      <AboutCareer />
    </div>
}

export default About