import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';

// Reusable Input Component
const InputField = ({ label, type, id }) => (
    <div className="flex grow justify-center items-start rounded-xl">
        {/* <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First name</label> */}
        <input type="text" id="first_name" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-black focus:border-black block w-full p-2.5 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-black dark:focus:border-black py-2.5 pr-16 pl-5" placeholder={label} required style={{ fontFamily: 'Raleway, sans-serif' }} />
    </div>
);

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
};

// Main Contact Form Component
const ContactForm = () => {
    const inputFields = [
        { label: 'Name', type: 'text', id: 'name' },
        { label: 'City', type: 'text', id: 'name' },
        { label: 'Email Id', type: 'email', id: 'email' },
        { label: 'Phone Number', type: 'tel', id: 'phone' }
    ];

    const [message, setMessage] = useState('');
    const [messageFocused, setMessageFocused] = useState(false);
    const maxLength = 30;

    const handleChange = (event) => {
        setMessage(event.target.value);
    }

    const handleFocus = () => {
        setMessageFocused(true);
    }

    const handleBlur = () => {
        setMessageFocused(false);
    }

    const validateMessageLength = () => {
        const words = message.trim().split(/\s+/).length;
        return words >= maxLength;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateMessageLength()) {
            alert("Message should at least 100 words...");
            return;
        }
        // Add submission logic here
    }

    return (
        <form className="flex flex-col self-stretch px-2 pt-10 pb-4 text-black max-md:max-w-full">
            <div className="self-center text-3xl font-semibold text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>GET IN TOUCH</div>
            <div className="flex gap-1.5 justify-between py-2 mt-8 max-md:flex-wrap">
                {inputFields.slice(0, 2).map(field => <InputField key={field.id} {...field} />)}
            </div>
            <div className="flex gap-1.5 justify-between py-2 mt-2.5 max-md:flex-wrap">
                {inputFields.slice(2, 4).map(field => <InputField key={field.id} {...field} />)}
            </div>
            <div className="flex flex-col items-start rounded-xl">
                <textarea id="message" rows="4" class="block p-2.5 w-full text-lg text-gray-900 bg-gray-50 rounded-lg focus:border-black dark:bg-white dark:placeholder-gray-400 dark:text-black pt-5 pr-16 pb-20 pl-5 mt-5" style={{ fontFamily: 'Raleway, sans-serif' }} placeholder="Leave a message...(Min. 30 Characters)" value={message} onChange={handleChange}></textarea>
                {/* {!validateMessageLength() && <p className="text-red-600">Message should be at least 100 words.</p>} */}
            </div>
            <button type="submit" className="justify-center self-center px-12 py-3 mt-7 text-3xl font-bold capitalize bg-orange-500 rounded-3xl max-md:px-5 transition-colors duration-300 hover:bg-orange-600" style={{ fontFamily: 'Raleway, sans-serif' }}>Submit</button>
        </form>
    );
};

// Main Component
const ContactPage = () => {
    return (
        <section className="flex justify-center items-center self-stretch px-16 py-5 max-md:px-5">
            <main className="w-full max-w-[1140px] max-md:max-w-full">
                <div className="flex gap-5 max-md:flex-col">
                    <aside className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
                        <header className="flex flex-col grow self-stretch py-11 text-white">
                            <h1 className="text-center ml-10 text-3xl font-semibold whitespace-nowrap max-md:ml-2.5" style={{ fontFamily: 'Raleway, sans-serif' }}>CONTACT US</h1>
                            <article className="flex flex-col px-10 mt-9 text-lg">
                                <p className="text-justify" style={{ fontFamily: 'Raleway, sans-serif' }}>For any queries regarding business quotes, parcel related support, or if you are just interested to get in touch - </p>
                                <p className="mt-4" style={{ fontFamily: 'Raleway, sans-serif' }}>Call on +91 9604036906</p>
                                <p className="mt-4" style={{ fontFamily: 'Raleway, sans-serif' }}>Reach out at admin@bfastservices.in</p>
                            </article>
                        </header>
                    </aside>
                    <ContactForm />
                </div>
            </main>
        </section>
    );
};

const ContactPageMobile = () => {
    const SocialIcon = ({ src, alt, onClick }) => (
        <img
            loading="lazy"
            src={src}
            alt={alt}
            className="shrink-0 w-6 aspect-square cursor-pointer"
            onClick={onClick}
        />
    );

    return (
        <header className="flex flex-col text-white mt-5">
            <h1 className="text-center text-2xl font-bold whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>CONTACT US</h1>
            <div className="flex flex-col items-center justify-between text-sm text-white">
                <div className="flex gap-3.5 mt-5 whitespace-nowrap">
                    <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/3867ebf889624d9e7a83e8b0cafff7e6a3ac007517fdb75ace3b7414ec945e86?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="Location Icon" />
                    <div style={{ fontFamily: 'Raleway, sans-serif' }}>
                        Urbana Jewels, Near Mandi Gate <br /> Sanganer, Jaipur 302029
                    </div>
                </div>
                <div className="flex gap-3.5 mt-5 text-center whitespace-nowrap">
                    <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcb69222e5d61cc9af49db599119f82cf468dabe5fcbe71dc5eae2b9cfdf0db3?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="Email Icon" />
                    <div style={{ fontFamily: 'Raleway, sans-serif' }}>admin@bfastservices.in</div>
                </div>
                <div className="flex gap-3.5 mt-5 text-center">
                    <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c3cb131fcedcf83310305a9c335750226fa53562d085876e5139200382d096a?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="Phone Icon" />
                    <div style={{ fontFamily: 'Raleway, sans-serif' }}>+91 9604036906</div>
                </div>
            </div>
        </header>
    );
};

const App = () => (
    <>
        <Desktop>
            <div className="flex flex-col pt-2.5">
                <ContactPage />
            </div>
        </Desktop>
        <Tablet>
            <div className="flex flex-col pt-2.5">
                <ContactPage />
            </div>
        </Tablet>
        <Mobile>
            <div className="flex flex-col pt-2.5">
                <ContactPageMobile />
            </div>
        </Mobile>
    </>
);

export default App;
