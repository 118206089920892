import React from 'react'
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const AboutBfast = () => {
  return (
    <div className="relative pt-8 md:pt-26 md:pb-26 overflow-hidden bg-fixed text-lg text-white" style={{ background: 'linear-gradient(to right, black 60%, #0c4a6e 50%)' }}>
      <div className="flex ">
        <div className="max-w-[54%] relative pr-0 xl:pr-10 lg:pr-10 md:pr-10 mt-8 mb-40 md:mt-0 content-none">
          <h1 className="text-start text-3xl mt-14 ml-20 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>About <span className="text-white font-normal">Bfast Services</span></h1>
          <div className="bottom-0 ml-20 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
          <div className="ml-20 text-white mt-6 text-xl leading-8" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '2' }}>
            We are India’s largest fully integrated logistics provider. We aim to build the operating system for commerce, through a combination of world-class infrastructure, logistics operations of the highest quality and cutting-edge engineering and technology capabilities.
          </div>
          <div className="ml-20 text-white mt-6 text-xl leading-8" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '2' }}>
            We are India’s largest fully integrated logistics provider. We aim to build the operating system for commerce, through a combination of world-class infrastructure, logistics operations of the highest quality and cutting-edge engineering and technology capabilities.
          </div>
        </div>
        <div className="mt-32 relative pr-20">
          <iframe className="shadow-orange-500 shadow-md aspect-none max-w-full" width="560" height="315" src="https://www.youtube.com/embed/Iqr3XIhSnUQ?si=G9kiwEkpvCjSN43h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <div className="absolute right-0 bottom-0 top-0 mt-12 mr-14 h-10 w-10 bg-orange-500"></div>
        </div>
      </div>
    </div>
  )
}

const AboutBfastTab = () => {
  return (
    <div className="relative pt-8 md:pt-26 md:pb-26 overflow-hidden bg-fixed text-lg text-white">
      <div className="flex flex-col-reverse">
        <div className="max-w-full relative pr-0 xl:pr-10 lg:pr-10 md:pr-10 mt-8 mb-40 md:mt-0 content-none">
          <h1 className="text-start text-3xl mt-14 ml-20 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>About <span className="text-white font-normal">Bfast Services</span></h1>
          <div className="bottom-0 ml-20 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
          <div className="ml-20 text-white mt-6 text-xl leading-8" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '2' }}>
            We are India’s largest fully integrated logistics provider. We aim to build the operating system for commerce, through a combination of world-class infrastructure, logistics operations of the highest quality and cutting-edge engineering and technology capabilities.
          </div>
          <div className="ml-20 text-white mt-6 text-xl leading-8" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '2' }}>
            We are India’s largest fully integrated logistics provider. We aim to build the operating system for commerce, through a combination of world-class infrastructure, logistics operations of the highest quality and cutting-edge engineering and technology capabilities.
          </div>
        </div>
        <div className="ml-20 relative pr-20">
          <iframe className="shadow-orange-500 shadow-md aspect-none max-w-full" style={{ zIndex: '10' }} width="100%" height="315" src="https://www.youtube.com/embed/Iqr3XIhSnUQ?si=G9kiwEkpvCjSN43h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  )
}

const AboutBfastMobile = () => {
  return (
    <div className="relative mt-10 pt-8 md:pt-26 md:pb-26 overflow-hidden bg-fixed text-lg text-white">
      <div className="flex flex-col-reverse">
        <div className="max-w-full relative pr-5 xl:pr-10 lg:pr-10 md:pr-10 mt-8 mb-40 md:mt-0 content-none">
          <h1 className="text-start text-xl mt-14 ml-10 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>About <span className="text-white font-normal">Bfast Services</span></h1>
          <div className="bottom-0 ml-10 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
          <div className="ml-10 text-white mt-6 text-md leading-8" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '2' }}>
            We are India’s largest fully integrated logistics provider. We aim to build the operating system for commerce, through a combination of world-class infrastructure, logistics operations of the highest quality and cutting-edge engineering and technology capabilities.
          </div>
          <div className="ml-10 text-white mt-6 text-md leading-8" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '2' }}>
            We are India’s largest fully integrated logistics provider. We aim to build the operating system for commerce, through a combination of world-class infrastructure, logistics operations of the highest quality and cutting-edge engineering and technology capabilities.
          </div>
        </div>
        <div className="ml-10 relative pr-10">
          <iframe className="shadow-orange-500 shadow-md aspect-none max-w-full" style={{ zIndex: '10' }} width="100%" height="315" src="https://www.youtube.com/embed/Iqr3XIhSnUQ?si=G9kiwEkpvCjSN43h" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  )
}

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col">
        <AboutBfast />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col">
        <AboutBfastTab />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col">
        <AboutBfastMobile />
      </div>
    </Mobile>
  </>
);

export default App;