import React from 'react'
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const AboutMission = () => {
  return (
    <div className="bg-sky-900">
      <div className="py-20">
        <div className="text-3xl ml-20 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Mission</div>
        <div className="bottom-0 ml-20 mt-1.5 w-16 h-1.5 bg-orange-500"></div>
        <div className="ml-20 text-gray-300 mt-7 text-xl w-10/12" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '1.5' }}>
          Our mission is to enable customers to operate flexible, reliable and resilient supply chains at the lowest costs. We provided supply chain solutions to a diverse base of over 30K+ active customers such as e-commerce marketplaces, direct-to-consumer e-tailers and enterprises and SMEs across several verticals such as FMCG, consumer durables, consumer electronics, lifestyle, retail, automotive and manufacturing.
          <br />
          <br />
          This is achieved through high-quality logistics infrastructure and network engineering, a vast network of domestic and global partners and significant investments in automation, all of which are orchestrated by our self-developed logistics operating system that drive network synergies within and across our services and enhance our value proposition to customers.
        </div>
      </div>
    </div>
  )
}

const AboutMissionTab = () => {
  return (
    <div className="bg-sky-900">
      <div className="py-20">
        <div className="text-2xl ml-20 text-white font-semibold" style={{ fontFamily: 'Raleway, sans-serif' }}>Mission</div>
        <div className="bottom-0 ml-20 mt-1.5 w-16 h-1.5 bg-orange-500"></div>
        <div className="ml-20 text-gray-300 mt-7 text-xl w-10/12" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '1.5' }}>
          Our mission is to enable customers to operate flexible, reliable and resilient supply chains at the lowest costs. We provided supply chain solutions to a diverse base of over 30K+ active customers such as e-commerce marketplaces, direct-to-consumer e-tailers and enterprises and SMEs across several verticals such as FMCG, consumer durables, consumer electronics, lifestyle, retail, automotive and manufacturing.
          <br />
          <br />
          This is achieved through high-quality logistics infrastructure and network engineering, a vast network of domestic and global partners and significant investments in automation, all of which are orchestrated by our self-developed logistics operating system that drive network synergies within and across our services and enhance our value proposition to customers.
        </div>
      </div>
    </div>
  )
}

const AboutMissionMobile = () => {
  return (
    <div className="bg-sky-900">
      <div className="py-20">
        <div className="text-xl ml-10 text-white font-semibold" style={{ fontFamily: 'Raleway, sans-serif' }}>Mission</div>
        <div className="bottom-0 ml-10 mt-1.5 w-14 h-1.5 bg-orange-500"></div>
        <div className="ml-10 text-gray-300 mt-7 text-md w-10/12" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '1.5' }}>
          Our mission is to enable customers to operate flexible, reliable and resilient supply chains at the lowest costs. We provided supply chain solutions to a diverse base of over 30K+ active customers such as e-commerce marketplaces, direct-to-consumer e-tailers and enterprises and SMEs across several verticals such as FMCG, consumer durables, consumer electronics, lifestyle, retail, automotive and manufacturing.
          <br />
          <br />
          This is achieved through high-quality logistics infrastructure and network engineering, a vast network of domestic and global partners and significant investments in automation, all of which are orchestrated by our self-developed logistics operating system that drive network synergies within and across our services and enhance our value proposition to customers.
        </div>
      </div>
    </div>
  )
}

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col">
        <AboutMission />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col">
        <AboutMissionTab />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col">
        <AboutMissionMobile />
      </div>
    </Mobile>
  </>
);

export default App;