// import React, { useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import { EffectCoverflow, Pagination } from 'swiper/modules';
// import { useMediaQuery } from 'react-responsive';

// export default function App() {
//   const isMobile = useMediaQuery({ maxWidth: 768 });
//   const isTablet = useMediaQuery({ maxWidth: 1024 });
//   const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

//   const handleSlideChange = (swiper) => {
//     setCurrentSlideIndex(swiper.activeIndex);
//   };

//   const paginationTexts = [
//     'Beauty Offerings',
//     'Life-saving Medications',
//     'Workshop Efficiency',
//     'Medication Delivery',
//     'Medication Delivery',
//     'Medication Delivery'
//   ];

//   return (
//     <>
//       <div style={{ height: '100%' }} className='pt-10 pb-10 mt-1'>
//         <h1 className="text-3xl font-bold text-center text-white mb-8" style={{ fontFamily: 'Raleway, sans-serif' }}>WE SERVE</h1>
//         <div style={{ position: 'relative', height: '100%' }}>
//           <Swiper
//             modules={[EffectCoverflow, Pagination]}
//             effect={'coverflow'}
//             loop={true}
//             slidesPerView={isMobile ? 1 : (isTablet ? 2 : 3)}
//             spaceBetween={30}
//             pagination={{
//               el: '.swiper-pagination',
//               clickable: true,
//               renderBullets: function (index, className) {
//                 return '<span class="' + className + ' text-white ">' + paginationTexts[index] + '</span>';
//               }
//             }}
//             centeredSlides={true}
//             grabCursor={true}
//             coverflowEffect={{
//               rotate: 0,
//               slideShadows: true,
//             }}
//             className="coverflow"
//             style={{ width: '90%', height: '100%' }}
//             onSlideChange={(swiper) => handleSlideChange(swiper)}
//           >
//             <SwiperSlide style={{ textAlign: 'center', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <div style={{ width: '500px', height: '300px', borderRadius: '20px', border: '0.7px solid #FFFFFF', position: 'relative', padding: '10px', backgroundImage: `url('https://cdn.builder.io/api/v1/image/assets/TEMP/7fefc1dff832969223bc2aa365d17ffbfc5715be227cc986ba151aecef1ad66b?apiKey=0be449f7ecab4405b7d056c64fb07853')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: '15px', width: '100%', height: '100%' }}>
//                   <p className='text-sm font-bold' style={{ fontFamily: 'Raleway, sans-serif', zIndex: 1, background: 'rgba(255, 255, 255, 0.6)', borderRadius: '20px', padding: '10px' }}>Enhance your beauty offerings with Bus Couriers. We deliver non-fragile cosmetic supplies swiftly, ensuring your shelves are stocked with the latest makeup, skincare, and beauty tools from across the country.</p>
//                 </div>
//               </div>
//             </SwiperSlide>

//             <SwiperSlide style={{ textAlign: 'center', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <div style={{ width: '500px', height: '300px', borderRadius: '20px', border: '0.7px solid #FFFFFF', position: 'relative', padding: '10px', backgroundImage: `url('https://cdn.builder.io/api/v1/image/assets/TEMP/1e8a30b0c431e271a99b2502f8b3a16508288b1edd0a78bd62181702f5b57ff5?apiKey=0be449f7ecab4405b7d056c64fb07853&')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: '15px', width: '100%', height: '100%' }}>
//                   <p className='text-sm font-bold' style={{ fontFamily: 'Raleway, sans-serif', zIndex: 1, background: 'rgba(255, 255, 255, 0.6)', borderRadius: '20px', padding: '20px' }}>Ensure the timely delivery of life-saving medications with our dependable courier services, dedicated to upholding strict safety and regulatory standards.</p>
//                 </div>
//               </div>
//             </SwiperSlide>

//             <SwiperSlide style={{ textAlign: 'center', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <div style={{ width: '500px', height: '300px', borderRadius: '20px', border: '0.7px solid #FFFFFF', position: 'relative', padding: '10px', backgroundImage: `url('https://cdn.builder.io/api/v1/image/assets/TEMP/6eb54236010d7559e47f1b5ab8442a39efcb6c2863da7885ec48fb2f2474c2a8?apiKey=0be449f7ecab4405b7d056c64fb07853&')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: '15px', width: '100%', height: '100%' }}>
//                   <p className='text-sm font-bold' style={{ fontFamily: 'Raleway, sans-serif', zIndex: 1, background: 'rgba(255, 255, 255, 0.6)', borderRadius: '20px', padding: '20px' }}> Keep your workshops running smoothly with our prompt delivery of essential auto parts, minimizing downtime and maximizing efficiency in your operations.</p>
//                 </div>
//               </div>
//             </SwiperSlide>

//             <SwiperSlide style={{ textAlign: 'center', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <div style={{ width: '500px', height: '300px', borderRadius: '20px', border: '0.7px solid #FFFFFF', position: 'relative', padding: '10px' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: '15px', width: '100%', height: '100%', borderRadius: '20px', background: 'rgba(87, 201, 222, 0.6)' }}>
//                   <p className='text-sm' style={{ fontFamily: 'Raleway, sans-serif' }}>Ensure the timely delivery of life-saving medications with our dependable courier services, dedicated to upholding strict safety and regulatory standards.</p>
//                 </div>
//               </div>
//             </SwiperSlide>

//             <SwiperSlide style={{ textAlign: 'center', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <div style={{ width: '500px', height: '300px', borderRadius: '20px', border: '0.7px solid #FFFFFF', position: 'relative', padding: '10px' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: '15px', width: '100%', height: '100%', borderRadius: '20px', background: 'rgba(87, 201, 222, 0.7)' }}>
//                   <p className='text-sm' style={{ fontFamily: 'Raleway, sans-serif' }}>Ensure the timely delivery of life-saving medications with our dependable courier services, dedicated to upholding strict safety and regulatory standards.</p>
//                 </div>
//               </div>
//             </SwiperSlide>

//             <SwiperSlide style={{ textAlign: 'center', fontSize: '18px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//               <div style={{ width: '500px', height: '300px', borderRadius: '20px', border: '0.7px solid #FFFFFF', position: 'relative', padding: '10px' }}>
//                 <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: '15px', width: '100%', height: '100%', borderRadius: '20px', background: 'rgba(87, 201, 222, 0.7)' }}>
//                   <p className='text-sm' style={{ fontFamily: 'Raleway, sans-serif' }}>Ensure the timely delivery of life-saving medications with our dependable courier services, dedicated to upholding strict safety and regulatory standards.</p>
//                 </div>
//               </div>
//             </SwiperSlide>
//             <div className="swiper-pagination mt-10 text-center flex space-x-20 justify-center" style={{ width: 'full', position: 'relative', bottom: '1rem' }}></div>
//           </Swiper>
//         </div>
//       </div>
//     </>
//   );
// }

import React from 'react'
import Marquee from 'react-fast-marquee';
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const AboutBfastTeam = () => {
  return (
    <div className="w-full min-h-full mt-20 flex flex-col justify-center items-center">
      <div className='w-[100%] flex flex-col'>
        <div className='flex space-y-2 flex-col text-center mb-14'>
          <span className='text-4xl font-bold text-white' style={{ fontFamily: 'Raleway, sans-serif' }}>
            WE SERVE
          </span>
          <div className="flex justify-center items-center">
            <div className="bottom-0 w-20 h-1.5 bg-orange-500"></div>
          </div>
        </div>
        <Marquee autoFill pauseOnHover>
          <div class="max-w-lg border border-black rounded-lg shadow dark:bg-black dark:border-black mx-6 relative">
            <img class="rounded-t-lg" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7fefc1dff832969223bc2aa365d17ffbfc5715be227cc986ba151aecef1ad66b?apiKey=0be449f7ecab4405b7d056c64fb07853" alt="" />
            <div class="absolute inset-0 flex justify-center items-center bor">
              <div class="bg-gray-100 bg-opacity-40 backdrop-blur-sm rounded-lg p-4 mx-3">
                <p class="text-md text-center text-gray-900 dark:text-gray-700 font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
                  Enhance your beauty offerings with Bus Couriers. We deliver non-fragile cosmetic supplies swiftly, ensuring your shelves are stocked with the latest makeup, skincare, and beauty tools from across the country.
                </p>
              </div>
            </div>
            <div class="p-5  text-center">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Cosmetic Brands</h5>
            </div>
          </div>

          <div class="max-w-lg border border-black rounded-lg shadow dark:bg-black dark:border-black mx-6 relative">
            <img class="rounded-t-lg" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1e8a30b0c431e271a99b2502f8b3a16508288b1edd0a78bd62181702f5b57ff5?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
            <div class="absolute inset-0 flex justify-center items-center">
              <div class="bg-gray-100 bg-opacity-40 backdrop-blur-sm rounded-lg p-7 mx-3">
                <p class="text-md text-center text-gray-900 dark:text-gray-700 font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
                  Ensure the timely delivery of life-saving medications with our dependable courier services, dedicated to upholding strict safety and regulatory standards.
                </p>
              </div>
            </div>
            <div class="p-5  text-center">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Pharma Companies</h5>
            </div>
          </div>

          <div class="max-w-lg border border-black rounded-lg shadow dark:bg-black dark:border-black mx-6 relative">
            <img class="rounded-t-lg" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6eb54236010d7559e47f1b5ab8442a39efcb6c2863da7885ec48fb2f2474c2a8?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
            <div class="absolute inset-0 flex justify-center items-center">
              <div class="bg-gray-100 bg-opacity-40 backdrop-blur-sm rounded-lg p-7 mx-3">
                <p class="text-md text-center text-gray-900 dark:text-gray-700 font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
                  Keep your workshops running smoothly with our prompt delivery of essential auto parts, minimizing downtime and maximizing efficiency in your operations.
                </p>
              </div>
            </div>
            <div class="p-5  text-center">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Automobile Workshops</h5>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  )
}

const AboutBfastTeammobile = () => {
  return (
    <div className="w-full min-h-full mt-20 flex flex-col justify-center items-center">
      <div className='w-[100%] flex flex-col'>
        <div className='flex space-y-2 flex-col text-center mb-14'>
          <span className='text-3xl font-bold text-white' style={{ fontFamily: 'Raleway, sans-serif' }}>
            WE SERVE
          </span>
          <div className="flex justify-center items-center">
            <div className="bottom-0 w-20 h-1.5 bg-orange-500"></div>
          </div>
        </div>
        <Marquee autoFill pauseOnHover>
          <div class="max-w-sm border border-black rounded-lg shadow dark:bg-black dark:border-black mx-6 relative">
            <img class="rounded-t-lg" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7fefc1dff832969223bc2aa365d17ffbfc5715be227cc986ba151aecef1ad66b?apiKey=0be449f7ecab4405b7d056c64fb07853" alt="" />
            <div class="absolute inset-0 flex justify-center items-center bor">
              <div class="bg-gray-100 bg-opacity-40 backdrop-blur-sm rounded-lg p-4 mx-2">
                <p class="text-xs text-center text-gray-900 dark:text-gray-700 font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
                  Enhance your beauty offerings with Bus Couriers. We deliver non-fragile cosmetic supplies swiftly, ensuring your shelves are stocked with the latest makeup, skincare, and beauty tools from across the country.
                </p>
              </div>
            </div>
            <div class="p-5  text-center">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Cosmetic Brands</h5>
            </div>
          </div>

          <div class="max-w-sm border border-black rounded-lg shadow dark:bg-black dark:border-black mx-6 relative">
            <img class="rounded-t-lg" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1e8a30b0c431e271a99b2502f8b3a16508288b1edd0a78bd62181702f5b57ff5?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
            <div class="absolute inset-0 flex justify-center items-center">
              <div class="bg-gray-100 bg-opacity-40 backdrop-blur-sm rounded-lg p-6 mx-2">
                <p class="text-xs text-center text-gray-900 dark:text-gray-700 font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
                  Ensure the timely delivery of life-saving medications with our dependable courier services, dedicated to upholding strict safety and regulatory standards.
                </p>
              </div>
            </div>
            <div class="p-5  text-center">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Pharma Companies</h5>
            </div>
          </div>

          <div class="max-w-sm border border-black rounded-lg shadow dark:bg-black dark:border-black mx-6 relative">
            <img class="rounded-t-lg" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6eb54236010d7559e47f1b5ab8442a39efcb6c2863da7885ec48fb2f2474c2a8?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="" />
            <div class="absolute inset-0 flex justify-center items-center">
              <div class="bg-gray-100 bg-opacity-40 backdrop-blur-sm rounded-lg p-6 mx-2">
                <p class="text-xs text-center text-gray-900 dark:text-gray-700 font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
                  Keep your workshops running smoothly with our prompt delivery of essential auto parts, minimizing downtime and maximizing efficiency in your operations.
                </p>
              </div>
            </div>
            <div class="p-5  text-center">
              <h5 class="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Automobile Workshops</h5>
            </div>
          </div>
        </Marquee>
      </div>
    </div>
  )
}

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col pt-2.5">
        <AboutBfastTeam />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col pt-2.5">
        <AboutBfastTeam />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col pt-2.5">
        <AboutBfastTeammobile />
      </div>
    </Mobile>
  </>
);

export default App;