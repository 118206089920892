import * as React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { createClient } from "@supabase/supabase-js";
import { useState } from "react";

const SocialIcon = ({ src, alt, onClick }) => (
  <img
    loading="lazy"
    src={src}
    alt={alt}
    className="shrink-0 w-6 aspect-square cursor-pointer"
    onClick={onClick}
  />
);

const LocationItem = ({ location }) => <div className="mt-4">{location}</div>;

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Locations = () => {
  const locations = ["Delhi", "Jaipur", "Gurugram"];
  return (
    <div className="flex flex-col px-5 text-lg text-white whitespace-nowrap max-md:mt-10">
      <div className="font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Locations</div>
      {locations.map((location, index) => (
        <LocationItem key={index} location={location} />
      ))}
    </div>
  );
};

const ContactInfo = () => (
  <div className="flex flex-col grow mt-2 ml-28 text-sm text-white max-md:mt-10">
    <div className="text-lg font-bold text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Contact Us</div>
    <div className="flex gap-3.5 mt-7">
      <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/3867ebf889624d9e7a83e8b0cafff7e6a3ac007517fdb75ace3b7414ec945e86?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="Location Icon" />
      <div style={{ fontFamily: 'Raleway, sans-serif' }}>
        Urbana Jewels, Near Mandi Gate <br /> Sanganer, Jaipur 302029
      </div>
    </div>
    <div className="flex gap-3.5 mt-5 whitespace-nowrap">
      <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcb69222e5d61cc9af49db599119f82cf468dabe5fcbe71dc5eae2b9cfdf0db3?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="Email Icon" />
      <div className="flex-auto my-auto" style={{ fontFamily: 'Raleway, sans-serif' }}>admin@bfastservices.in</div>
    </div>
    <div className="flex gap-3.5 mt-4">
      <SocialIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c3cb131fcedcf83310305a9c335750226fa53562d085876e5139200382d096a?apiKey=0be449f7ecab4405b7d056c64fb07853&" alt="Phone Icon" />
      <div className="flex-auto my-auto" style={{ fontFamily: 'Raleway, sans-serif' }}>+91 9604036906</div>
    </div>
  </div>
);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
const supabase = createClient(supabaseUrl, supabaseKey);

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // const addEmail = async () => {
  //   const { data, error } = await supabase
  //     .from("subscribed_users")
  //     .insert([{ email: email }],)
  //     .select();

  //   if (!error && data) {
  //     console.log(data);
  //     setEmail("");
  //     setSubmitted(true);
  //   }
  //   else {
  //     console.log(error);
  //   }
  // };
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   addEmail();
  // };
  
  const addEmail = async () => {
    try {
      const { data, error } = await supabase
        .from("subscribed_users")
        .insert([{ email: email }]);

      if (!error && data) {
        console.log(data);
        setEmail("");
        setSubmitted(true);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className="flex flex-col flex-1 mt-2">
      {submitted ? (
        <div className="text-lg font-bold text-center text-white" >Thank you for submitting, Stay Tuned!</div>
      ) : (
        <>
          <div className="text-lg font-bold text-center text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>Subscribe</div>
          <form onSubmit={(e) => {
            e.preventDefault();
            addEmail();
          }} className="mt-5 ml-12">
            <label htmlFor="emailInput" className="sr-only" style={{ fontFamily: 'Raleway, sans-serif' }}>
              Email
            </label>
            <input
              id="emailInput"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className="justify-center py-2.5 text-sm leading-6 whitespace-nowrap bg-white border border-solid border-zinc-400 text-black w-48 pl-2" style={{ fontFamily: 'Raleway, sans-serif' }}
            />
            <button type="submit" className="justify-center items-center px-16 py-2 mt-4 text-sm text-white whitespace-nowrap bg-sky-500 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:focus:ring-sky-800 max-md:px-5 w-48 xs:ml-5" style={{ fontFamily: 'Raleway, sans-serif' }}>
              Subscribe
            </button>
          </form>
        </>
      )}
      <div className="mt-9 text-base font-bold text-white ml-12" style={{ fontFamily: 'Raleway, sans-serif' }}>Follow us</div>
      <div className="flex gap-4 pr-20 mt-5 max-md:pr-5 ml-12">
        <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
          <FaFacebook className="W-6 aspect-square text-white hover:text-violet-900 " alt="Facebook Icon" />
        </a>
        <a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer">
          <FaTwitter className="w-6 aspect-square text-white hover:text-violet-900 " alt="Twitter Icon" />
        </a>
        <a href="https://www.instagram.com/youraccount" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="w-6 aspect-square text-white hover:text-violet-900 " alt="Instagram Icon" />
        </a>
        <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="w-6 aspect-square text-white hover:text-violet-900 " alt="LinkedIn Icon" />
        </a>
      </div>
    </div>
  );
};

const Footer = () => (
  <div className="flex relative gap-5 justify-between mt-16 w-full max-w-[1067px] max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
    <div className="justify-center py-0.5 pr-7 text-xs  text-left font-light text-white whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>
      © {new Date().getFullYear()} All Rights Reserved
    </div>
    <div className="flex gap-5 justify-between text-sm text-zinc-500">
      <a href="/updatedpage" className="hover:text-gray-300" style={{ fontFamily: 'Raleway, sans-serif' }}>Privacy Policy</a>
      <a href="/terms-of-use" className="hover:text-gray-300" style={{ fontFamily: 'Raleway, sans-serif' }}>Terms of Use</a>
      <a href="/legal" className="hover:text-gray-300" style={{ fontFamily: 'Raleway, sans-serif' }}>Legal</a>
      <a href="/site-map" className="hover:text-gray-300" style={{ fontFamily: 'Raleway, sans-serif' }}>Site Map</a>
    </div>
  </div>
);

const MainContent = () => (
  <div className="flex overflow-hidden relative flex-col items-center px-20 pt-20 pb-6 w-full fill-black min-h-[397px] max-md:px-5 max-md:max-w-full">
    <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/ecc87747b752bbc20e7ac002ec73ad26d8f4fdef4a62166358697ec47988423f?apiKey=0be449f7ecab4405b7d056c64fb07853&"
      alt="Background Image"
      className="object-cover absolute inset-0 size-full"
    />
    <div className="flex relative gap-5 justify-between items-start mt-1.5 w-full max-w-[1115px] max-md:flex-wrap max-md:max-w-full ">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/38a1a301fb4c2b15fcf32f0c58861de8ed2da8069c8588dc5b914f1d35c8a7cb?apiKey=0be449f7ecab4405b7d056c64fb07853&"
        alt="Logo"
        className="shrink-0 self-stretch my-auto max-w-full aspect-[1.89] w-[110px]"
      />
      <div className="flex-auto max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-[70%] max-md:ml-0 max-md:w-full">
            <ContactInfo />
          </div>
          <div className="flex flex-col ml-5 w-[30%] max-md:ml-0 max-md:w-full">
            <Locations />
          </div>
        </div>
      </div>
      <Subscribe />
    </div>
    <Footer />
  </div>
);

const MainContentTab = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const addEmail = async () => {
    try {
      const { data, error } = await supabase
        .from("subscribed_users")
        .insert([{ email: email }]);

      if (!error && data) {
        console.log(data);
        setEmail("");
        setSubmitted(true);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const locations = ["Delhi", "Jaipur", "Gurugram"];

  return (
    <>
      <div className="flex overflow-hidden relative flex-col px-20 pb-6 fill-sky-800 min-h-[397px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ecc87747b752bbc20e7ac002ec73ad26d8f4fdef4a62166358697ec47988423f?apiKey=0be449f7ecab4405b7d056c64fb07853&"
          alt="Background Image"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="relative flex flex-col items-center justify-center w-full max-w-screen-lg">
          {submitted ? (
            <div className="text-lg font-bold text-center text-white">
              Thank you for submitting, Stay Tuned!
            </div>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addEmail();
              }}
              className="flex flex-col items-center w-full mt-32"
            >
              <label htmlFor="email" className="mb-7 text-4xl font-medium text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>
                Subscribe
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2.5 text-center text-2xl bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Email For Future Updates"
                required style={{ fontFamily: 'Raleway, sans-serif' }} />
              <div>
                <button
                  type="submit"
                  className="w-full px-20 py-2.5 mt-5 text-3xl text-white bg-purple-700 rounded-3xl hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-700"
                  style={{ backgroundColor: '#2B1154', fontFamily: 'Raleway, sans-serif' }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex-row  mt-5 mb-5 text-white whitespace-nowrap">
          <div className="font-bold text-center text-4xl" style={{ fontFamily: 'Raleway, sans-serif' }}>Locations</div>
          <p className="flex flex-row gap-10 text-3xl px-10" style={{ fontFamily: 'Raleway, sans-serif' }}>
            {locations.map((location, index) => (
              <LocationItem key={index} location={location} />
            ))}
          </p>
        </div>
        <div className="flex justify-between">
          <div className="flex-row mt-5 mb-5 text-white whitespace-nowrap">
            <div className="text-center text-4xl font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Follow us</div>
            <div className="flex flex-row gap-10 text-4xl mt-5 px-10" style={{ fontFamily: 'Raleway, sans-serif' }}>
              <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="W-6 aspect-square text-white hover:text-violet-900 " alt="Facebook Icon" />
              </a>
              <a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="w-6 aspect-square text-white hover:text-violet-900 " alt="Twitter Icon" />
              </a>
              <a href="https://www.instagram.com/youraccount" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="w-6 aspect-square text-white hover:text-violet-900 " alt="Instagram Icon" />
              </a>
              <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="w-6 aspect-square text-white hover:text-violet-900 " alt="LinkedIn Icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="text-center py-2 pr-7 text-2xl font-light text-black whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>
          © {new Date().getFullYear()} All Rights Reserved
        </div>
      </div>
    </>
  );
};

const MainContentMobile = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const addEmail = async () => {
    try {
      const { data, error } = await supabase
        .from("subscribed_users")
        .insert([{ email: email }]);

      if (!error && data) {
        console.log(data);
        setEmail("");
        setSubmitted(true);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const locations = ["Delhi", "Jaipur", "Gurugram"];
  return (
    <>
      <div className="flex overflow-hidden relative flex-col px-20 pb-6 fill-sky-800 min-h-[397px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/ecc87747b752bbc20e7ac002ec73ad26d8f4fdef4a62166358697ec47988423f?apiKey=0be449f7ecab4405b7d056c64fb07853&"
          alt="Background Image"
          className="absolute inset-0 object-cover w-full h-full"
        />
        <div className="relative flex flex-col items-center justify-center w-full max-w-screen-lg">
          {submitted ? (
            <div className="text-md font-bold text-center text-white">
              Thank you for submitting!
            </div>
          ) : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addEmail();
              }}
              className="flex flex-col items-center w-full mt-32"
            >
              <label htmlFor="email" className="mb-7 text-2xl font-medium text-gray-900 dark:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}>
                Subscribe
              </label>
              <input
                type="email"
                id="email"
                className="w-full p-2.5 text-center text-sm bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Enter Email For Future Updates"
                required style={{ fontFamily: 'Raleway, sans-serif' }} />
              <div>
                <button
                  type="submit"
                  className="w-full px-10 py-2.5 mt-5 text-md text-white bg-purple-700 rounded-3xl hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-purple-700"
                  style={{ backgroundColor: '#2B1154', fontFamily: 'Raleway, sans-serif' }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
      <div className="flex justify-around">
        <div className="flex-row  mt-3 mb-3 text-white whitespace-nowrap">
          <div className="font-bold text-center text-sm" style={{ fontFamily: 'Raleway, sans-serif' }}>Locations</div>
          <p className="flex flex-row gap-1 text-xs px-5 flex-1" style={{ fontFamily: 'Raleway, sans-serif' }}>
            {locations.map((location, index) => (
              <LocationItem key={index} location={location} />
            ))}
          </p>
        </div>
        <div className="flex justify-around">
          <div className="flex-row mt-3 mb-3 text-white whitespace-nowrap">
            <div className="text-center text-sm font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Follow us</div>
            <div className="flex flex-row gap-1 text-xs mt-5 px-5 flex-1" style={{ fontFamily: 'Raleway, sans-serif' }}>
              <a href="https://www.facebook.com/yourpage" target="_blank" rel="noopener noreferrer">
                <FaFacebook className="W-6 aspect-square text-white hover:text-violet-900 " alt="Facebook Icon" />
              </a>
              <a href="https://twitter.com/youraccount" target="_blank" rel="noopener noreferrer">
                <FaTwitter className="w-6 aspect-square text-white hover:text-violet-900 " alt="Twitter Icon" />
              </a>
              <a href="https://www.instagram.com/youraccount" target="_blank" rel="noopener noreferrer">
                <FaInstagram className="w-6 aspect-square text-white hover:text-violet-900 " alt="Instagram Icon" />
              </a>
              {/* <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
                <FaLinkedin className="w-6 aspect-square text-white hover:text-violet-900 " alt="LinkedIn Icon" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="text-center py-2 pr-7 text-md font-light text-black whitespace-nowrap" style={{ fontFamily: 'Raleway, sans-serif' }}>
          © {new Date().getFullYear()} All Rights Reserved
        </div>
      </div>

    </>
  );
};

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col pt-2.5 bg-gray-950">
        <MainContent />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col pt-2.5 bg-gray-950">
        <MainContentTab />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col pt-2.5 bg-gray-950">
      <MainContentMobile />
      </div>
    </Mobile>
  </>
);

export default App;