import React from 'react'
import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
};

const AboutCareer = () => {
    return (
        <div className="mt-28 h-full w-full overflow-hidden bg-fixed text-lg text-white flex" style={{ background: 'linear-gradient(to right, #0c4a6e 60%, black 40%)' }}>
            <div className="w-1/2 P-10">
                <div className="text-2xl mt-14 ml-20 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Explore a career with Bfast Services</div>
                <div className="bottom-0 ml-20 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
                <div className="ml-20 text-white mt-7 text-xl w-10/12" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '1.5' }}>
                    We’re looking for curious, passionate people with a desire to solve challenging business problems. We move fast, operate under pressure and with limited resources. If you have a deep interest to relentlessly move our business forward and share our joy at making the world a smaller place, apply below
                </div>
                <div className="mt-16 flex ml-20">
                    <div>
                        <button type="button" class="grow focus:outline-none text-white border-[1px] border-white hover:bg-orange-500 hover:border-none focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-xl px-5 py-2.5 mb-2 dark:hover:bg-orange-500 dark:focus:ring-purple-800 w-full" style={{ fontFamily: 'Raleway, sans-serif' }}>See Job Openings &#8594;</button>
                    </div>
                </div>
            </div>
            <div className="flex w-2/5 relative h-fit mt-14">
                <img src="https://plus.unsplash.com/premium_photo-1661409078904-42334551db0c?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y291cmllcnxlbnwwfHwwfHx8MA%3D%3D" alt="careers" className="aspect-w-16 aspect-h-9 w-fit h-fit mb-14" />
                <div className="absolute right-0 bottom-0 top-0 -mr-4 mt-12 h-10 w-10 bg-orange-500"></div>
            </div>
        </div>
    )
}

const AboutCareerTab = () => {
    return (
        <div className="mt-28 h-full w-full overflow-hidden bg-fixed text-lg text-white" style={{ background: '#0c4a6e' }}>
            <div className="mt-14 relative">
                <img src="https://plus.unsplash.com/premium_photo-1661409078904-42334551db0c?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y291cmllcnxlbnwwfHwwfHx8MA%3D%3D" alt="careers" className="h-auto max-w-[90%] ml-10 mr-10 mb-14 rounded-lg " />
            </div>
            <div className="w-full h-full">
                <div className="text-2xl mt-14 ml-10 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Explore a career with BFast Services</div>
                <div className="bottom-0 ml-10 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
                <div className="ml-10 text-white mt-7 text-xl w-10/12" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '1.5' }}>
                    We’re looking for curious, passionate people with a desire to solve challenging business problems. We move fast, operate under pressure and with limited resources. If you have a deep interest to relentlessly move our business forward and share our joy at making the world a smaller place, apply below
                </div>
                <div className="mt-10 flex ml-10">
                    <div>
                        <button type="button" class="grow focus:outline-none text-white border-[1px] border-white hover:bg-orange-500 hover:border-none focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-xl px-5 py-2.5 mb-2 dark:hover:bg-orange-500 dark:focus:ring-purple-800 w-full" style={{ fontFamily: 'Raleway, sans-serif' }}>See Job Openings &#8594;</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AboutCareerMobile = () => {
    return (
        <div className="mt-28 h-full w-full overflow-hidden bg-fixed text-lg text-white" style={{ background: '#0c4a6e' }}>
            <div className="mt-14 relative">
                <img src="https://plus.unsplash.com/premium_photo-1661409078904-42334551db0c?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8Y291cmllcnxlbnwwfHwwfHx8MA%3D%3D" alt="careers" className="h-auto max-w-[80%] ml-9 mb-14 rounded-lg " />
            </div>
            <div className="w-full h-full">
                <div className="text-xl mt-14 ml-10 text-white font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>Explore a career with BFast Services</div>
                <div className="bottom-0 ml-10 mt-1.5 w-20 h-1.5 bg-orange-500"></div>
                <div className="ml-10 text-white mt-7 text-md w-10/12" style={{ fontFamily: 'Raleway, sans-serif', lineHeight: '1.5' }}>
                    We’re looking for curious, passionate people with a desire to solve challenging business problems. We move fast, operate under pressure and with limited resources. If you have a deep interest to relentlessly move our business forward and share our joy at making the world a smaller place, apply below
                </div>
                <div className="mt-10 flex justify-center">
                    <div>
                        <button type="button" class="grow focus:outline-none text-white border-[1px] border-white hover:bg-orange-500 hover:border-none focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 mb-2 dark:hover:bg-orange-500 dark:focus:ring-purple-800 w-full" style={{ fontFamily: 'Raleway, sans-serif' }}>See Job Openings &#8594;</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const App = () => (
    <>
        <Desktop>
            <div className="flex flex-col pt-2.5">
                <AboutCareer />
            </div>
        </Desktop>
        <Tablet>
            <div className="flex flex-col pt-2.5">
                <AboutCareerTab />
            </div>
        </Tablet>
        <Mobile>
            <div className="flex flex-col pt-2.5">
                <AboutCareerMobile />
            </div>
        </Mobile>
    </>
);

export default App;