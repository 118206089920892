import React from 'react';
import { TypeAnimation } from 'react-type-animation';
import { useMediaQuery } from 'react-responsive';

const BannerButton = () => (

  <button
    type="button"
    className="justify-center self-start px-3 py-4 mt-16 text-xl leading-5 text-center text-white whitespace-nowrap rounded-md border border-sky-800 border-solid bg-violet-950 max-md:mt-10 transition duration-300 ease-in-out hover:bg-purple-800 hover:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}
  >
    Get in Touch
  </button>
);

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

const Banner = ({ imageSrc, altText }) => (
  // <section className="py-7">
  //   <div className="max-w-7xl mx-1 px-4 sm:px-6 lg:px-8">
  //     <div className="flex gap-3 max-md:flex-col max-md:gap-0">
  //       <div className="flex flex-col w-[45%] max-md:ml-0 max-md:w-full">
  //         <div className="flex flex-col mt-14 font-bold max-md:mt-10 max-md:max-w-full">
  //           <h1 className="text-6xl text-white leading-[78px] max-md:max-w-full max-md:text-4xl max-md:leading-[54px]" style={{ fontFamily: 'Raleway, sans-serif' }}>
  //             {/* Simplifying the <span className="text-orange-500">Bus Couriers</span> */}
  //             <span>Simplifying the<br /></span>
  //             <TypeAnimation className="text-orange-500 text-5xl max-sm:text-3xl" style={{ fontFamily: 'Raleway, sans-serif' }}
  //               sequence={[
  //                 'Intercity Couriers',
  //                 1000,
  //                 'B2B Deliveries',
  //                 1000,
  //                 'Bus Couriers',
  //                 1000,
  //                 'Last Mile Deliveries',
  //                 1000
  //               ]}
  //               wrapper="span"
  //               speed={70}
  //               repeat={Infinity}
  //             />
  //           </h1>
  //           <BannerButton />
  //         </div>
  //       </div>
  //       <div className="flex flex-col ml-5 w-[55%] max-md:ml-0 max-md:w-full">
  //         <img
  //           loading="lazy"
  //           src={imageSrc}
  //           alt={altText}
  //           className="grow w-full aspect-[1.27] max-md:mt-10 max-md:max-w-full"
  //         />
  //       </div>
  //     </div>
  //   </div>
  // </section>
  <>
    <div className="relative mt-10 mx-8 rounded-lg bg-cover bg-center bg-no-repeat" style={{ backgroundImage: "url('https://images.pexels.com/photos/4481259/pexels-photo-4481259.jpeg?auto=compress&cs=tinysrgb&w=1200')", height: '500px' }}>
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8) 100%, rgba(0,0,0,0) 100%)' }}>
        <div className="flex h-full items-center ml-10">
          <div className="text-white">
            <h1 className="text-6xl text-white w-full h-full font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
              <span>Deliveries Courier within 24 Hours<br /></span>
              <TypeAnimation className="text-orange-500 text-5xl font-medium" style={{ fontFamily: 'Raleway, sans-serif' }}
                sequence={[
                  'JAIPUR, RAJASTHAN',
                  1000,
                  'NEW DELHI, DELHI',
                  1000,
                  'GURUGRAM, HARYANA',
                  1000,
                  'LUCKNOW, UTTAR PRADESH',
                  1000,
                  'KANPUR, UTTAR PRADESH',
                  1000,
                  'KOTA, RAJASTHAN',
                  1000,
                  'VARANASI, UTTAR PRADESH',
                  1000,
                  'JODHPUR, RAJASTHAN',
                  1000
                ]}
                wrapper="span"
                speed={70}
                repeat={Infinity}
              />
            </h1>
            <div className="w-full justify-center self-start">
              <button
                type="button"
                className=" leading-5 focus:outline-none text-white border-[1px] border-white hover:bg-orange-500 hover:border-none focus:ring-4 focus:ring-purple-300 font-medium rounded-md text-2xl px-6 py-4 mb-2 dark:hover:bg-orange-500 dark:focus:ring-purple-800 mt-16" style={{ fontFamily: 'Raleway, sans-serif' }}
              >
                Get in Touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="relative bottom-10 left-20 right-0 h-20 bg-orange-500 w-20"></div>
  </>
);

const BannerMobile = ({ imageSrc, altText }) => (
  // <>
  //   <div className="relative rounded-lg bg-cover bg-no-repeat p-12" style={{ backgroundImage: "url('https://images.pexels.com/photos/4481259/pexels-photo-4481259.jpeg?auto=compress&cs=tinysrgb&w=1200')", height: '400px' }}>
  //     <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
  //       <div className="flex h-full items-center ml-10">
  //         <div className="text-white">
  //           <h1 className="text-6xl text-white max-md:max-w-full max-sm:text-4xl max-md:leading-[54px]" style={{ fontFamily: 'Raleway, sans-serif' }}>
  //             {/* Simplifying the <span className="text-orange-500">Bus Couriers</span> */}
  //             <span>Simplifying the<br /></span>
  //             <TypeAnimation className="text-orange-500 text-5xl max-sm:text-3xl" style={{ fontFamily: 'Raleway, sans-serif' }}
  //               sequence={[
  //                 'Intercity Couriers',
  //                 1000,
  //                 'B2B Deliveries',
  //                 1000,
  //                 'Bus Couriers',
  //                 1000,
  //                 'Last Mile Deliveries',
  //                 1000
  //               ]}
  //               wrapper="span"
  //               speed={70}
  //               repeat={Infinity}
  //             />
  //           </h1>
  //           <button
  //             type="button"
  //             className="justify-center self-start px-3 py-4 mt-16 text-xl leading-5 text-center text-white whitespace-nowrap rounded-md border border-sky-800 border-solid bg-violet-950 max-md:mt-10 transition duration-300 ease-in-out hover:bg-purple-800 hover:text-white" style={{ fontFamily: 'Raleway, sans-serif' }}
  //           >
  //             Get in Touch
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </>

  <>
    <div className="relative w-full mt-3.5 rounded-lg bg-cover bg-center bg-no-repeat" style={{
      backgroundImage: "url('https://images.pexels.com/photos/4481259/pexels-photo-4481259.jpeg?auto=compress&cs=tinysrgb&w=1200')", height: '300px', /* Reduced height for mobile */
      maxHeight: '500px', /* Max height for larger screens */
      minHeight: '100px',
    }}>
      <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed" style={{ background: 'linear-gradient(to right, rgba(0,0,0,0.8) 20%, rgba(0,0,0,0) 100%)' }}>
        <div className="flex h-full items-center ml-10">
          <div className="text-white">
            <h1 className="text-3xl text-orange font-bold" style={{ fontFamily: 'Raleway, sans-serif' }}>
              <span>Delivering Parcels within 24 Hours<br /></span>
              <TypeAnimation className="text-white-500 text-2xl font-medium" style={{ fontFamily: 'Raleway, sans-serif' }}
                sequence={[
                  'JAIPUR, RAJASTHAN',
                  1000,
                  'NEW DELHI, DELHI',
                  1000,
                  'GURUGRAM, HARYANA',
                  1000,
                  'LUCKNOW, UTTAR PRADESH',
                  1000,
                  'KANPUR, UTTAR PRADESH',
                  1000,
                  'KOTA, RAJASTHAN',
                  1000,
                  'VARANASI, UTTAR PRADESH',
                  1000,
                  'JODHPUR, RAJASTHAN',
                  1000
                ]}
                wrapper="span"
                speed={70}
                repeat={Infinity}
              />
            </h1>
            <div className="w-full justify-center self-start">
              <button
                type="button"
                className=" leading-5 focus:outline-none text-white border-[1px] border-white hover:bg-orange-500 hover:border-none focus:ring-4 focus:ring-purple-300 font-medium rounded-md text-md px-5 py-3 mb-2 dark:hover:bg-orange-500 dark:focus:ring-purple-800 mt-8" style={{ fontFamily: 'Raleway, sans-serif' }}
              >
                Get in Touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const BannerHeader = () => {
  return (
    <>
      <div>
        <Banner imageSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/7127d7e549786bb698ceca9751f6816d2de18e6ce6031a550462b7916829d71e?apiKey=0be449f7ecab4405b7d056c64fb07853&" altText="Dynamic and comprehensive view of bus courier services." />
      </div>
    </>
  );
};

const App = () => (
  <>
    <Desktop>
      <div className="flex flex-col pt-2.5">
        <BannerHeader />
      </div>
    </Desktop>
    <Tablet>
      <div className="flex flex-col pt-2.5">
        <BannerHeader />
      </div>
    </Tablet>
    <Mobile>
      <div className="flex flex-col pt-2.5">
        <BannerMobile />
      </div>
    </Mobile>
  </>
);

export default App;