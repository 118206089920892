import React from "react";
import OurServices from "../components/OurServices";
// import CostCalculator from "../components/CostCalculator";
import WeServe from "../components/WeServe";
import BetterChoice from "../components/BetterChoice";
import Header from "../components/Header";
import ContactPage from "../components/ContactPage";



const Home = () => {
  return <div>
    <Header />
    {/* <CostCalculator /> */}
    <OurServices />
    <BetterChoice />
    <WeServe />
    <ContactPage />
  </div>
};

export default Home;

