import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import NavBar from "./components/NavBar";
import Services from "./pages/Services";
import TrackCourier from "./pages/TrackCourier";
import Footer from "./components/Footer";

const App = () => {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <br />
        <br />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/trackcourier" element={<TrackCourier />} />
        </Routes>
        <Footer />
      </div>
      
    </Router>
  );
};

export default App;
